

.App {
   text-align: center;
}

.App-logo {
   height: 40vmin;
   pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
   .App-logo {
      animation: App-logo-spin infinite 20s linear;
   }
}

.App-header {
   background-color: #282c34;
   min-height: 100vh;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   font-size: calc(10px + 2vmin);
   color: white;
}

.App-link {
   color: #61dafb;
}

.App-FileSelected {
   display: inline-block;
   padding: 5px 12px;
   cursor: pointer;
   background-color: #07a0e7;
   color: #ffffff;
   border: 0;
   border-radius: 2px;
   box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
   white-space: nowrap;
   transition: 0.4s;
}

.App-FileSelected:hover {
   background-color: #2fbdf5; /* Green */
   color: white;
   transition: 0.4s;
}

.App-ExportButton {
   cursor: pointer;
   background-color: #07a0e7;
   color: #ffffff;
   border: 0;
   border-radius: 2px;
   box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
   white-space: nowrap;
   transition: 0.4s;
}

.App-ExportButton:hover {
   background-color: #2fbdf5; /* Green */
   color: white;
   transition: 0.4s;
}

input[type='file'] {
   display: none;
}

@keyframes App-logo-spin {
   from {
      transform: rotate(0deg);
   }
   to {
      transform: rotate(360deg);
   }
}
